import * as React from 'react'
import Layout from '../components/layout'
import ContainerCentered from '../components/container-centered'
import Header4xl from '../components/header-4xl'
import ParagraphBig from '../components/paragraph-big'
import ReportsFeed from '../components/reports-feed'


const Reports = () => {
  
  return (
    <Layout>

      <section id="hero">
        <ContainerCentered bgColor={"bg-slate-700"} minHeight={"py-32"}>
          <Header4xl textColor={"text-white"} textAlign={"text-center"}>Carbon Switch Reports</Header4xl>
          <ParagraphBig textColor={"text-white"} textAlign={"text-center"}>
          Research and insights about climate change, electrification, and home energy.
          </ParagraphBig>
        </ContainerCentered>
      </section>

      <section id="past-newsletters">
        <ReportsFeed />
      </section>

    </Layout>
  )
}

export default Reports