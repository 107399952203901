import React from "react";

const ParagraphBig = ({ children, textColor, textAlign, customStyle }) => {

  return (
    <p className={`mt-4 font-normal text-xl sm:text-2xl ${textColor} ${textAlign} ${customStyle}`}>
      {children}
    </p>
  )

}

export default ParagraphBig