import * as React from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ReportsFeed = () => {

  return (
    <StaticQuery 

      query={graphql`
        query Reports {
          allWpPost(
            sort: {order: DESC, fields: [date]}
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "reports"}}}}}
          ) {
            edges {
              page: node {
                id
                uri
                title
                content
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}

      render={data => (
        <div className="max-w-4xl mx-auto px-3 sm:px-10 lg:px-12">
          <div className="my-20">
            <h1 className='text-center'>Latest reports</h1>
              {data.allWpPost.edges.map(({ page }, index) => (
                <div 
                  id="parent-JSX-div" 
                  className='px-8 sm:px-0'
                > 
                  <div 
                    id="top-dividing-line" 
                    className='border-t border-slate-200 my-8'
                  ></div>
                  <div 
                    id="content-cards"
                    key={index}
                    className="flex flex-col-reverse sm:items-center sm:flex-row"
                  >
                    <Link to={page.uri} className='sm:max-w-xs'>
                      <GatsbyImage 
                        image={getImage(page.featuredImage.node.localFile.childImageSharp.gatsbyImageData)}
                      />
                    </Link>
                    <Link 
                      to={page.uri}
                      className="text-3xl font-extrabold text-slate-800 no-underline mb-4 sm:pl-4" 
                    >
                      {page.title}
                    </Link>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
      
    />
  )

}

export default ReportsFeed