import React from "react";

const ContainerCentered = ({ children, bgColor, minHeight, maxWidth, customStyling }) => {

  return (
    <div className={`flex flex-row justify-center items-center ${bgColor} ${minHeight} ${customStyling}`}>
      <div className={`${maxWidth} flex flex-col items-center px-4`}>
        {children}
      </div>
    </div>
  )
}

export default ContainerCentered