import React from "react";

const Header4xl = ({ children, textColor, textAlign, customStyle }) => {

  return (
    <h2 className={`text-4xl lg:text-5xl max-w-4xl ${textColor} ${textAlign} ${customStyle}`}>
      {children}
    </h2>
  )

}

export default Header4xl